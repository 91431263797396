import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { getTitle } from '../../utility';
import CompanyDetails from './CompanyDetails';
import AdminForm from './AdminDetails';
import CompanySettingForm from './companySettingForm';
import ManageSSOForm from './manageSSO';
import AccessModule from './accessModule';
import ThemeForm from '../../CompanyApp/Settings/Theme/themeForm';
import CompanyActiveUsers from './CompanyActiveUser';
import PayrollCycle from './payrollCycle';
import MultiEntityForm from './CompanyMultiEntityForm';

export default class CompanyDetailsLandingPage extends Component {
    constructor(props) {
        super(props);
        const { company } = props.location.state || {};
        this.state = {
            value: 0,
            companyDetails: company,
            activeTab: 'CompanyInfo',
        };
    }
    handleChange = (event, newValue) => {
        this.setState({ value: newValue })
    };
    handleTabChange = (tab) => {
        this.setState({ activeTab: tab });
    };
    render() {
        const { companyDetails,activeTab } = this.state;
        return (
            <div className="page-wrapper">
                <Helmet>
                    <title> Company Details | {getTitle()}</title>
                </Helmet>

                <div className="mt-4 content container-fluid">
                    <div className="tab-content">
                        <div className="subMenu_box row user-tabs">
                            <div className="nav-box">
                                <div className="page-headerTab">
                                    <h3 style={{ color: 'white' }} className="page-title">{companyDetails && companyDetails.name} Company's Details</h3>
                                    <div className="p-0 col-lg-12 col-md-12 col-sm-12 sub-nav-tabs">
                                        <ul className="nav nav-items">
                                            <li className="nav-item"><a href="#details" data-toggle="tab" className={`nav-link ${this.state.activeTab === 'CompanyInfo' ? 'active' : ''}`} onClick={() => this.handleTabChange('CompanyInfo')}>Company's Information</a> </li>
                                            <li className="nav-item"><a href="#multi-entity" data-toggle="tab" className={`nav-link ${this.state.activeTab === 'MultiEntity' ? 'active' : ''}`} onClick={() => this.handleTabChange('MultiEntity')}> Multi Entity </a> </li>
                                            <li className="nav-item"><a href="#admin" data-toggle="tab" className={`nav-link ${this.state.activeTab === 'CompanyAdmin' ? 'active' : ''}`} onClick={() => this.handleTabChange('CompanyAdmin')}>Company's Admins</a> </li>
                                            <li className="nav-item"><a href="#theme" data-toggle="tab" className={`nav-link ${this.state.activeTab === 'Theme' ? 'active' : ''}`} onClick={() => this.handleTabChange('Theme')}>Theme</a> </li>
                                            <li className="nav-item"><a href="#access-module" data-toggle="tab" className={`nav-link ${this.state.activeTab === 'AccessModule' ? 'active' : ''}`} onClick={() => this.handleTabChange('AccessModule')}>Access Module</a> </li>
                                            <li className="nav-item"><a href="#company-setting" data-toggle="tab" className={`nav-link ${this.state.activeTab === 'CompanySetting' ? 'active' : ''}`} onClick={() => this.handleTabChange('CompanySetting')}>Company's Settings</a> </li>
                                            <li className="nav-item"><a href="#sso" data-toggle="tab" className={`nav-link ${this.state.activeTab === 'ManageSso' ? 'active' : ''}`} onClick={() => this.handleTabChange('ManageSso')}>Manage SSO</a> </li>
                                            <li className="nav-item"><a href="#active-users" data-toggle="tab" className={`nav-link ${this.state.activeTab === 'ActiveUser' ? 'active' : ''}`} onClick={() => this.handleTabChange('ActiveUser')}>Active Users</a> </li>
                                            <li className="nav-item"><a href="#payroll-cycle" data-toggle="tab" className={`nav-link ${this.state.activeTab === 'PayrollCycle' ? 'active' : ''}`} onClick={() => this.handleTabChange('PayrollCycle')}>Payroll Setting</a> </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-content">
                            {activeTab == 'CompanyInfo' && <div id="details" className="pro-overview moduleSetupPageContainer tab-pane fade show active">
                               <CompanyDetails company={this.state.companyDetails}></CompanyDetails>
                            </div>}
                            {activeTab == 'MultiEntity' &&  <div id="multi-entity" className="pro-overview moduleSetupPageContainer tab-pane fade show active">
                             <MultiEntityForm company={this.state.companyDetails}></MultiEntityForm>
                            </div>}
                            {activeTab == 'CompanyAdmin' && <div id="admin" className="pro-overview moduleSetupPageContainer tab-pane fade show active">
                             <AdminForm company={this.state.companyDetails}></AdminForm>
                            </div>}
                            {activeTab == 'Theme' &&  <div id="theme" className="pro-overview moduleSetupPageContainer tab-pane fade show active">
                              <ThemeForm company={this.state.companyDetails}></ThemeForm>
                            </div>}
                            {activeTab == 'AccessModule' && <div id="access-module" className="pro-overview moduleSetupPageContainer tab-pane fade show active">
                             <AccessModule company={this.state.companyDetails}></AccessModule>
                            </div>}
                            {activeTab == 'CompanySetting' && <div id="company-setting" className="pro-overview moduleSetupPageContainer tab-pane fade show active">
                             <CompanySettingForm company={this.state.companyDetails}></CompanySettingForm>
                            </div>}
                            {activeTab == 'ManageSso' && <div id="sso" className="pro-overview moduleSetupPageContainer tab-pane fade show active">
                             <ManageSSOForm company={this.state.companyDetails}></ManageSSOForm>
                            </div>}
                            {activeTab == 'ActiveUser' && <div id="active-users" className="pro-overview moduleSetupPageContainer tab-pane fade show active">
                             <CompanyActiveUsers company={this.state.companyDetails}></CompanyActiveUsers>
                            </div>}
                            {activeTab == 'PayrollCycle' && <div id="payroll-cycle" className="pro-overview moduleSetupPageContainer tab-pane fade show active">
                             <PayrollCycle company={this.state.companyDetails}></PayrollCycle>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}