

export const Country_List = [
  { id: 1, currency: "AED - United Arab Emirates", countryCode: "AE", currencyName: "Dirham", minorUnit: "Fils" },
  { id: 2, currency: "AFN - Afghanistan", countryCode: "AF", currencyName: "Afghani", minorUnit: "Pul" },
  { id: 3, currency: "ALL - Albania", countryCode: "AL", currencyName: "Lek", minorUnit: "Qindarka" },
  { id: 4, currency: "AMD - Armenia", countryCode: "AM", currencyName: "Dram", minorUnit: "Luma" },
  { id: 5, currency: "ANG - Netherlands Antilles", countryCode: "AN", currencyName: "Guilder", minorUnit: "Cent" },
  { id: 6, currency: "AOA - Angola", countryCode: "AO", currencyName: "Kwanza", minorUnit: "Cêntimos" },
  { id: 7, currency: "ARS - Argentina", countryCode: "AR", currencyName: "Peso", minorUnit: "Centavo" },
  { id: 8, currency: "AUD - Australia", countryCode: "AU", currencyName: "Dollar", minorUnit: "Cent" },
  { id: 9, currency: "AWG - Aruba", countryCode: "AW", currencyName: "Florin", minorUnit: "Cent" },
  { id: 10, currency: "AZN - Azerbaijan", countryCode: "AZ", currencyName: "Manat", minorUnit: "Garaqalpaq" },
  { id: 11, currency: "BAM - Bosnia and Herzegovina", countryCode: "BA", currencyName: "Mark", minorUnit: "Fening" },
  { id: 12, currency: "BBD - Barbados", countryCode: "BB", currencyName: "Dollar", minorUnit: "Cent" },
  { id: 13, currency: "BDT - Bangladesh", countryCode: "BD", currencyName: "Taka", minorUnit: "Poisha" },
  { id: 14, currency: "BGN - Bulgaria", countryCode: "BG", currencyName: "Lev", minorUnit: "Stotinki" },
  { id: 15, currency: "BHD - Bahrain", countryCode: "BH", currencyName: "Dinar", minorUnit: "Fils" },
  { id: 16, currency: "BIF - Burundi", countryCode: "BI", currencyName: "Franc", minorUnit: "Centime" },
  { id: 17, currency: "BMD - Bermuda", countryCode: "BM", currencyName: "Dollar", minorUnit: "Cent" },
  { id: 18, currency: "BND - Brunei", countryCode: "BN", currencyName: "Dollar", minorUnit: "Sen" },
  { id: 19, currency: "BOB - Bolivia", countryCode: "BO", currencyName: "Boliviano", minorUnit: "Centavo" },
  { id: 20, currency: "BRL - Brazil", countryCode: "BR", currencyName: "Real", minorUnit: "Centavo" },
  { id: 21, currency: "BSD - Bahamas", countryCode: "BS", currencyName: "Dollar", minorUnit: "Cent" },
  { id: 22, currency: "BTN - Bhutan", countryCode: "BT", currencyName: "Ngultrum", minorUnit: "Chhertum" },
  { id: 23, currency: "BWP - Botswana", countryCode: "BW", currencyName: "Pula", minorUnit: "Thebe" },
  { id: 24, currency: "BYN - Belarus", countryCode: "BY", currencyName: "Belarusian Ruble", minorUnit: "Kapyeyka" },
  { id: 25, currency: "BZD - Belize", countryCode: "BZ", currencyName: "Dollar", minorUnit: "Cent" },
  { id: 26, currency: "CAD - Canada", countryCode: "CA", currencyName: "Dollar", minorUnit: "Cent" },
  { id: 27, currency: "CDF - Democratic Republic of the Congo", countryCode: "CD", currencyName: "Congolese Franc", minorUnit: "Centime" },
  { id: 28, currency: "CHF - Switzerland", countryCode: "CH", currencyName: "Franc", minorUnit: "Rappen" },
  { id: 29, currency: "CLP - Chile", countryCode: "CL", currencyName: "Peso", minorUnit: "Centavo" },
  { id: 30, currency: "CNY - China", countryCode: "CN", currencyName: "Yuan", minorUnit: "Jiao" },
  { id: 31, currency: "COP - Colombia", countryCode: "CO", currencyName: "Peso", minorUnit: "Centavo" },
  { id: 32, currency: "CRC - Costa Rica", countryCode: "CR", currencyName: "Colón", minorUnit: "Céntimo" },
  { id: 33, currency: "CUP - Cuba", countryCode: "CU", currencyName: "Peso", minorUnit: "Centavo" },
  { id: 34, currency: "CVE - Cape Verde", countryCode: "CV", currencyName: "Escudo", minorUnit: "Centavo" },
  { id: 35, currency: "CZK - Czech Republic", countryCode: "CZ", currencyName: "Koruna", minorUnit: "Haléř" },
  { id: 36, currency: "DJF - Djibouti", countryCode: "DJ", currencyName: "Franc", minorUnit: "Centime" },
  { id: 37, currency: "DKK - Denmark", countryCode: "DK", currencyName: "Krone", minorUnit: "Øre" },
  { id: 38, currency: "DOP - Dominican Republic", countryCode: "DO", currencyName: "Peso", minorUnit: "Centavo" },
  { id: 39, currency: "DZD - Algeria", countryCode: "DZ", currencyName: "Dinar", minorUnit: "Santeem" },
  { id: 40, currency: "EGP - Egypt", countryCode: "EG", currencyName: "Pound", minorUnit: "Piastre" },
  { id: 41, currency: "ERN - Eritrea", countryCode: "ER", currencyName: "Nakfa", minorUnit: "Cents" },
  { id: 42, currency: "ETB - Ethiopia", countryCode: "ET", currencyName: "Birr", minorUnit: "Santim" },
  { id: 43, currency: "EUR - Eurozone", countryCode: "EU", currencyName: "Euro", minorUnit: "Cent" },
  { id: 44, currency: "FJD - Fiji", countryCode: "FJ", currencyName: "Dollar", minorUnit: "Cent" },
  { id: 45, currency: "FKP - Falkland Islands", countryCode: "FK", currencyName: "Pound", minorUnit: "Penny" },
  { id: 46, currency: "FOK - Faroe Islands", countryCode: "FO", currencyName: "Krone", minorUnit: "Øre" },
  { id: 47, currency: "GBP - United Kingdom", countryCode: "GB", currencyName: "Pound", minorUnit: "Penny" },
  { id: 48, currency: "GEL - Georgia", countryCode: "GE", currencyName: "Lari", minorUnit: "Tetris" },
  { id: 49, currency: "GHS - Ghana", countryCode: "GH", currencyName: "Cedi", minorUnit: "Ghanaian Gana" },
  { id: 50, currency: "GIP - Gibraltar", countryCode: "GI", currencyName: "Pound", minorUnit: "Penny" },
  { id: 51, currency: "GMD - Gambia", countryCode: "GM", currencyName: "Dalasi", minorUnit: "Butut" },
  { id: 52, currency: "GNF - Guinea", countryCode: "GN", currencyName: "Franc", minorUnit: "Centime" },
  { id: 53, currency: "GTQ - Guatemala", countryCode: "GT", currencyName: "Quetzal", minorUnit: "Centavo" },
  { id: 54, currency: "GYD - Guyana", countryCode: "GY", currencyName: "Dollar", minorUnit: "Cent" },
  { id: 55, currency: "HKD - Hong Kong", countryCode: "HK", currencyName: "Dollar", minorUnit: "Cent" },
  { id: 56, currency: "HNL - Honduras", countryCode: "HN", currencyName: "Lempira", minorUnit: "Centavo" },
  { id: 57, currency: "HRK - Croatia", countryCode: "HR", currencyName: "Kuna", minorUnit: "Lipa" },
  { id: 58, currency: "HTG - Haiti", countryCode: "HT", currencyName: "Gourde", minorUnit: "Centime" },
  { id: 59, currency: "HUF - Hungary", countryCode: "HU", currencyName: "Forint", minorUnit: "Filler" },
  { id: 60, currency: "IDR - Indonesia", countryCode: "ID", currencyName: "Rupiah", minorUnit: "Sen" },
  { id: 61, currency: "ILS - Israel", countryCode: "IL", currencyName: "New Shekel", minorUnit: "Agorot" },
  { id: 62, currency: "INR - India", countryCode: "IN", currencyName: "Rupee", minorUnit: "Paisa" },
  { id: 63, currency: "IQD - Iraq", countryCode: "IQ", currencyName: "Dinar", minorUnit: "Fils" },
  { id: 64, currency: "IRR - Iran", countryCode: "IR", currencyName: "Rial", minorUnit: "Dinar" },
  { id: 65, currency: "ISK - Iceland", countryCode: "IS", currencyName: "Krona", minorUnit: "Eyrir" },
  { id: 66, currency: "JMD - Jamaica", countryCode: "JM", currencyName: "Dollar", minorUnit: "Cent" },
  { id: 67, currency: "JOD - Jordan", countryCode: "JO", currencyName: "Dinar", minorUnit: "Piastre" },
  { id: 68, currency: "JPY - Japan", countryCode: "JP", currencyName: "Yen", minorUnit: "Sen" },
  { id: 69, currency: "KES - Kenya", countryCode: "KE", currencyName: "Shilling", minorUnit: "Cent" },
  { id: 70, currency: "KGS - Kyrgyzstan", countryCode: "KG", currencyName: "Som", minorUnit: "Tyiyn" },
  { id: 71, currency: "KHR - Cambodia", countryCode: "KH", currencyName: "Riel", minorUnit: "Sen" },
  { id: 72, currency: "KPW - North Korea", countryCode: "KP", currencyName: "Won", minorUnit: "Chon" },
  { id: 73, currency: "KRW - South Korea", countryCode: "KR", currencyName: "Won", minorUnit: "Jeon" },
  { id: 74, currency: "KWD - Kuwait", countryCode: "KW", currencyName: "Dinar", minorUnit: "Fils" },
  { id: 75, currency: "KYD - Cayman Islands", countryCode: "KY", currencyName: "Dollar", minorUnit: "Cent" },
  { id: 76, currency: "KZT - Kazakhstan", countryCode: "KZ", currencyName: "Tenge", minorUnit: "Tiyn" },
  { id: 77, currency: "LAK - Laos", countryCode: "LA", currencyName: "Kip", minorUnit: "Att" },
  { id: 78, currency: "LBP - Lebanon", countryCode: "LB", currencyName: "Pound", minorUnit: "Piastre" },
  { id: 79, currency: "LKR - Sri Lanka", countryCode: "LK", currencyName: "Rupee", minorUnit: "Cent" },
  { id: 80, currency: "LRD - Liberia", countryCode: "LR", currencyName: "Dollar", minorUnit: "Cent" },
  { id: 81, currency: "LSL - Lesotho", countryCode: "LS", currencyName: "Loti", minorUnit: "Sente" },
  { id: 82, currency: "LYD - Libya", countryCode: "LY", currencyName: "Dinar", minorUnit: "Dirham" },
  { id: 83, currency: "MAD - Morocco", countryCode: "MA", currencyName: "Dirham", minorUnit: "Centime" },
  { id: 84, currency: "MDL - Moldova", countryCode: "MD", currencyName: "Leu", minorUnit: "Bani" },
  { id: 85, currency: "MGA - Madagascar", countryCode: "MG", currencyName: "Ariary", minorUnit: "Iraimbilanja" },
  { id: 86, currency: "MKD - North Macedonia", countryCode: "MK", currencyName: "Denar", minorUnit: "Denari" },
  { id: 87, currency: "MMK - Myanmar", countryCode: "MM", currencyName: "Kyat", minorUnit: "Pya" },
  { id: 88, currency: "MNT - Mongolia", countryCode: "MN", currencyName: "Tögrög", minorUnit: "Mongolian Tögrög" },
  { id: 89, currency: "MRU - Mauritania", countryCode: "MR", currencyName: "Ouguiya", minorUnit: "Kouza" },
  { id: 90, currency: "MUR - Mauritius", countryCode: "MU", currencyName: "Rupee", minorUnit: "Cent" },
  { id: 91, currency: "MVR - Maldives", countryCode: "MV", currencyName: "Rufiyaa", minorUnit: "Laari" },
  { id: 92, currency: "MWK - Malawi", countryCode: "MW", currencyName: "Kwacha", minorUnit: "Tambala" },
  { id: 93, currency: "MXN - Mexico", countryCode: "MX", currencyName: "Peso", minorUnit: "Centavo" },
  { id: 94, currency: "MYR - Malaysia", countryCode: "MY", currencyName: "Ringgit", minorUnit: "Sen" },
  { id: 95, currency: "MZN - Mozambique", countryCode: "MZ", currencyName: "Metical", minorUnit: "Centavo" },
  { id: 96, currency: "NAD - Namibia", countryCode: "NA", currencyName: "Dollar", minorUnit: "Cent" },
  { id: 97, currency: "NGN - Nigeria", countryCode: "NG", currencyName: "Naira", minorUnit: "Kobo" },
  { id: 98, currency: "NIO - Nicaragua", countryCode: "NI", currencyName: "Córdoba", minorUnit: "Centavo" },
  { id: 99, currency: "NOK - Norway", countryCode: "NO", currencyName: "Krone", minorUnit: "Øre" },
  { id: 100, currency: "NPR - Nepal", countryCode: "NP", currencyName: "Rupee", minorUnit: "Paisa" },
  { id: 101, currency: "NZD - New Zealand", countryCode: "NZ", currencyName: "Dollar", minorUnit: "Cent" },
  { id: 102, currency: "OMR - Oman", countryCode: "OM", currencyName: "Rial", minorUnit: "Baisa" },
  { id: 103, currency: "PAB - Panama", countryCode: "PA", currencyName: "Balboa", minorUnit: "Centésimo" },
  { id: 104, currency: "PEN - Peru", countryCode: "PE", currencyName: "Sol", minorUnit: "Céntimo" },
  { id: 105, currency: "PGK - Papua New Guinea", countryCode: "PG", currencyName: "Kina", minorUnit: "Toea" },
  { id: 106, currency: "PHP - Philippines", countryCode: "PH", currencyName: "Peso", minorUnit: "Centavo" },
  { id: 107, currency: "PKR - Pakistan", countryCode: "PK", currencyName: "Rupee", minorUnit: "Paisa" },
  { id: 108, currency: "PLN - Poland", countryCode: "PL", currencyName: "Zloty", minorUnit: "Grosz" },
  { id: 109, currency: "PYG - Paraguay", countryCode: "PY", currencyName: "Guaraní", minorUnit: "Céntimo" },
  { id: 110, currency: "QAR - Qatar", countryCode: "QA", currencyName: "Riyal", minorUnit: "Dirham" },
  { id: 111, currency: "RON - Romania", countryCode: "RO", currencyName: "Leu", minorUnit: "Bani" },
  { id: 112, currency: "RSD - Serbia", countryCode: "RS", currencyName: "Dinar", minorUnit: "Para" },
  { id: 113, currency: "RUB - Russia", countryCode: "RU", currencyName: "Ruble", minorUnit: "Kopek" },
  { id: 114, currency: "RWF - Rwanda", countryCode: "RW", currencyName: "Franc", minorUnit: "Centime" },
  { id: 115, currency: "SAR - Saudi Arabia", countryCode: "SA", currencyName: "Riyal", minorUnit: "Halala" },
  { id: 116, currency: "SBD - Solomon Islands", countryCode: "SB", currencyName: "Dollar", minorUnit: "Cent" },
  { id: 117, currency: "SCR - Seychelles", countryCode: "SC", currencyName: "Rupee", minorUnit: "Cent" },
  { id: 118, currency: "SDG - Sudan", countryCode: "SD", currencyName: "Pound", minorUnit: "Piastre" },
  { id: 119, currency: "SEK - Sweden", countryCode: "SE", currencyName: "Krona", minorUnit: "Öre" },
  { id: 120, currency: "SGD - Singapore", countryCode: "SG", currencyName: "Dollar", minorUnit: "Cent" },
  { id: 121, currency: "SHP - Saint Helena", countryCode: "SH", currencyName: "Pound", minorUnit: "Penny" },
  { id: 122, currency: "SLL - Sierra Leone", countryCode: "SL", currencyName: "Leone", minorUnit: "Cent" },
  { id: 123, currency: "SOS - Somalia", countryCode: "SO", currencyName: "Shilling", minorUnit: "Cent" },
  { id: 124, currency: "SRD - Suriname", countryCode: "SR", currencyName: "Dollar", minorUnit: "Cent" },
  { id: 125, currency: "SSP - South Sudan", countryCode: "SS", currencyName: "Pound", minorUnit: "Piastre" },
  { id: 126, currency: "STN - São Tomé and Príncipe", countryCode: "ST", currencyName: "Dobra", minorUnit: "Cêntimo" },
  { id: 127, currency: "SYP - Syria", countryCode: "SY", currencyName: "Pound", minorUnit: "Piastre" },
  { id: 128, currency: "SZL - Eswatini", countryCode: "SZ", currencyName: "Lilangeni", minorUnit: "Cent" },
  { id: 129, currency: "THB - Thailand", countryCode: "TH", currencyName: "Baht", minorUnit: "Satang" },
  { id: 130, currency: "TJS - Tajikistan", countryCode: "TJ", currencyName: "Somoni", minorUnit: "Diram" },
  { id: 131, currency: "TMT - Turkmenistan", countryCode: "TM", currencyName: "Manat", minorUnit: "Tiyin" },
  { id: 132, currency: "TND - Tunisia", countryCode: "TN", currencyName: "Dinar", minorUnit: "Millime" },
  { id: 133, currency: "TOP - Tonga", countryCode: "TO", currencyName: "Paʻanga", minorUnit: "Seniti" },
  { id: 134, currency: "TRY - Turkey", countryCode: "TR", currencyName: "Lira", minorUnit: "Kuruş" },
  { id: 135, currency: "TTD - Trinidad and Tobago", countryCode: "TT", currencyName: "Dollar", minorUnit: "Cent" },
  { id: 136, currency: "TWD - Taiwan", countryCode: "TW", currencyName: "New Dollar", minorUnit: "Jiao" },
  { id: 137, currency: "TZS - Tanzania", countryCode: "TZ", currencyName: "Shilling", minorUnit: "Cent" },
  { id: 138, currency: "UAH - Ukraine", countryCode: "UA", currencyName: "Hryvnia", minorUnit: "Kopiyka" },
  { id: 139, currency: "UGX - Uganda", countryCode: "UG", currencyName: "Shilling", minorUnit: "Cent" },
  { id: 140, currency: "USD - United States", countryCode: "US", currencyName: "Dollar", minorUnit: "Cent" },
  { id: 141, currency: "UYU - Uruguay", countryCode: "UY", currencyName: "Peso", minorUnit: "Centésimo" },
  { id: 142, currency: "UZS - Uzbekistan", countryCode: "UZ", currencyName: "Som", minorUnit: "Tiyin" },
  { id: 143, currency: "VEF - Venezuela", countryCode: "VE", currencyName: "Bolívar", minorUnit: "Céntimo" },
  { id: 144, currency: "VND - Vietnam", countryCode: "VN", currencyName: "Dong", minorUnit: "Hào" },
  { id: 145, currency: "VUV - Vanuatu", countryCode: "VU", currencyName: "Vatu", minorUnit: "Cent" },
  { id: 146, currency: "WST - Samoa", countryCode: "WS", currencyName: "Tala", minorUnit: "Sene" },
  { id: 147, currency: "XAF - Central African CFA", countryCode: "XAF", currencyName: "Franc", minorUnit: "Centime" },
  { id: 148, currency: "XCD - East Caribbean", countryCode: "XCD", currencyName: "Dollar", minorUnit: "Cent" },
  { id: 149, currency: "XOF - West African CFA", countryCode: "XOF", currencyName: "Franc", minorUnit: "Centime" },
  { id: 150, currency: "XPF - CFP", countryCode: "XPF", currencyName: "Franc", minorUnit: "Centime" },
  { id: 151, currency: "YER - Yemen", countryCode: "YE", currencyName: "Rial", minorUnit: "Fils" },
  { id: 152, currency: "ZAR - South Africa", countryCode: "ZA", currencyName: "Rand", minorUnit: "Cent" },
  { id: 153, currency: "ZMW - Zambia", countryCode: "ZM", currencyName: "Kwacha", minorUnit: "Ngwee" },
  { id: 154, currency: "ZWL - Zimbabwe", countryCode: "ZW", currencyName: "Dollar", minorUnit: "Cent" },
  { id: 155, currency: "ECS - Ecuador", countryCode: "EC", currencyName: "Dollar", minorUnit: "Cent" },
  { id: 156, currency: "LAK - Laos", countryCode: "LA", currencyName: "Kip", minorUnit: "Att" },
  { id: 157, currency: "LKR - Sri Lanka", countryCode: "LK", currencyName: "Rupee", minorUnit: "Cent" },
  { id: 158, currency: "NAD - Namibia", countryCode: "NA", currencyName: "Dollar", minorUnit: "Cent" },
  { id: 159, currency: "PKR - Pakistan", countryCode: "PK", currencyName: "Rupee", minorUnit: "Paisa" },
  { id: 160, currency: "SVC - El Salvador", countryCode: "SV", currencyName: "Colón", minorUnit: "Centavo" },
  { id: 161, currency: "TMT - Turkmenistan", countryCode: "TM", currencyName: "Manat", minorUnit: "Tiyin" },
];